import { toast } from "react-toastify";

export function handleHttpErrors(e) {
  if (e.response) {
    const res = e.response
    console.log(res.data);
    console.log(res.status);
    console.log(res.headers);
    console.log(res);

    if (res.status === 400) {
      const msg = res.data.message 
      toast(msg)
    }

    if (res.status === 401) {
      const msg = res.data.error 
      // toast('กรุณาเข้าสู่ระบบ')
    }

    if (res.status === 422) {
      const msg = res.data.message 
      const inputError = res.data.errors
      // toast(msg)

      for (const inputName in inputError) {
        console.log(`${inputName}: ${inputError[inputName]}`);
        toast(inputError[inputName][0])
      }
    }

    if (res.status === 500) {
      const msg = res.data?.message 
      toast(msg)
    }
  }
  
  return e
}