import { useEffect } from "react";
import HomeFooter from "../components/HomeFooter";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { useTutorialContext } from "../hooks/TutorialContext";
import { ADMIN_PHONE_NUMBER } from "../_constant";

export default function Handbook() {
  const navigate = useNavigate()
  const { setTutorialStep } = useTutorialContext()

  useEffect(() => {
    setTutorialStep(1)
  }, [])

  const startClicked = () => {
    navigate('/')
  }

  const loginClicked = () => {
    navigate('/login')
  }

  const registerClicked = () => {
    navigate('/register')
  }

  return (
    <div class="page">
      <PageTitle title={'คู่มือใช้งานแอปพลิเคชัน'} backTo={'/'} />

      <div class="page page-handbook-top">
        1. เลือกคอร์สที่ต้องการลงทะเบียน (คอร์สเรียน/สอบใบอนุญาติ) <br /> <br />
        2. ตรวจสอบรายละเอียดของคอร์สให้ถี่ถ้วน <br /> <br />
        3. ทำแบบทดสอบออนไลน์ <br /> <br />
        4. ลงทะเบียนได้ทันที! <br /> <br />
        5. ชำระเงินผ่านธนาคาร หรือชำระเป็นเงินสดได้ที่โรงเรียน ในวันนัดหมาย <br /> <br />

        <div class="small-text">
          ** หากท่านต้องการเรียนหรือสอบใบอนุญาตนอกเหนือเวลาที่กำหนดไว้ ท่านสามารถติดต่อแอดมินได้ผ่าน LINE โดยตรง หรือโทร {ADMIN_PHONE_NUMBER}
        </div>
      </div>
      <div class="page page-handbook-bottom">
        <button type="button" class="btn btn-primary single-page-button" onClick={startClicked}>เริ่มต้น</button>
        
        <div class="small-text">สมัครสมาชิกเพื่อรับข้อมูลข่าวสารเพิ่มเติม</div>
        <div class="small-text"><span onClick={loginClicked}>เข้าสู่ระบบ</span> | <span onClick={registerClicked}>สมัครสมาชิก</span></div>
      </div>

      <HomeFooter />
    </div>
  )
}