import React, { useContext } from 'react';
import { Outlet, Navigate  } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';

const ProtectedRoute = (props) => {
  const { user, authChecked } = useContext(UserContext)

  if (! authChecked)
    return false

  return user ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoute;