import { useContext, useEffect } from "react";
import CourseCard from "../components/CourseCard";
import { COURSE_TYPE_LEARNING, CourseContext } from "../hooks/CourseContext";
import PageTitle from "../components/PageTitle";
import { useSearchParams } from "react-router-dom";

export default function Course() {
  const { coursesLoaded, learningCourses, certificateCourses, fetchCourses } = useContext(CourseContext)
  const [searchParams] = useSearchParams()
  const courseType = searchParams.get('type')
  const typeText = courseType === COURSE_TYPE_LEARNING ? 'คอร์สเรียน' : 'สอบใบอนุญาต'
  const displayCourses = courseType === COURSE_TYPE_LEARNING ? learningCourses : certificateCourses

  useEffect(() => {
    if (! coursesLoaded) {
      fetchCourses({ type: courseType })
    }
  }, [])

  return (
    <>
      <div class="page page-course">
        <PageTitle title={typeText} backTo={'/'} />
        <div class="small-text">ผู้สมัครต้องผ่านการสอบวัดผลพื้นฐานก่อนลงทะเบียน{typeText}</div>
        {displayCourses.map((course) => <CourseCard course={course} />)}
      </div>
    </>
  )
}