import ApiClient, { authHeader } from "../utils/request";

export function login({ phone_number }) {
  return ApiClient.post('/login', { phone_number })
}

export function logout() {
  return ApiClient.post('/logout')
}

export function getMe() {
  return ApiClient.get('/me')
}

export function updateMe({ name, phone_number, age }) {
  return ApiClient.post('/me', validateUserFormData({ name, phone_number, age }))
}

export function createUser({ name, phone_number, age }) {
  return ApiClient.post('/register', validateUserFormData({ name, phone_number, age }))
}

const validateUserFormData = (form) => {
  const data = { ...form }

  if (form.phone_number)
    data.phone_number = data.phone_number.replaceAll('-', '')
  
  if (form.citizen_number)
    data.citizen_number = data.citizen_number.replaceAll('-', '')

  return data
}