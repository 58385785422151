import { useContext, useEffect, useState } from "react";
import PageTitle from "../components/PageTitle";
import { UserContext } from "../hooks/UserContext";
import { toast } from 'react-toastify';
import Button from "../components/Button";
import HomeFooter from "../components/HomeFooter";

export default function Account() {
  const { user, logout, updateUserProfile, userFormValidate } = useContext(UserContext)
  const [form, setForm] = useState({ name: '', phone_number: '' })

  useEffect(() => {
    if (user) {
      setForm({ name: user.name, phone_number: user.phone_number, age: user.age })
    }
  }, [user])

  const updateProfileClicked = () => {
    updateUserProfile(form)
      .then(() => toast('แก้ไขข้อมูลบัญชีสำเร็จ'))
      .catch(console.error)
  }

  const logoutClicked = () => {
    logout(form)
      .then(() => toast('ออกจากระบบ'))
      .catch(console.error)
  }

  const formInputChanged = (e) => {
    const { name, val, isValid } = userFormValidate(e.target.name, e.target.value)

    if (isValid) {
      setForm({ ...form, 
        [name]: val
      })
    }
  }

  return (
    <>
      <div class="page page-account">
        <PageTitle title={'ข้อมูลบัญชี'} backTo={'/'} />
        
        <form>
          <div class="form-group">
            <label>ชื่อ-นามสกุล</label>
            <input name="name" type="text" class="form-control" value={form.name} onChange={formInputChanged} />
          </div>
          <div class="form-group">
            <label>เบอร์โทรศัพท์</label>
            <input name="phone_number" type="text" class="form-control" value={form.phone_number} onChange={formInputChanged} />
          </div>
          <div class="form-group">
            <label>อายุ</label>
            <input name="age" type="text" class="form-control" value={form.age} onChange={formInputChanged} />
          </div>
       
          <Button onClick={updateProfileClicked}>แก้ไขข้อมูล</Button>
          <div class="divider"></div>
          <Button className="btn-logout" onClick={logoutClicked}>ออกจากระบบ</Button>
        </form>

        <HomeFooter />
      </div>
    </>
  )
}