import { useNavigate } from "react-router-dom"

export default function BackButton({ backTo }) {
  const navigate = useNavigate()
  
  const backClicked = () => {
    if (backTo) {
      navigate(backTo)
    } else {
      navigate(-1)
    }
  }

  return (
    <div class="back-button" onClick={backClicked}>&#8249;</div>
  )
}