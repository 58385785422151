import { useContext } from "react"
import { COURSE_SCHEDULE_STATUS_DUED, COURSE_SCHEDULE_STATUS_ONGOING, courseDatesFormat, courseScheduleStatusText } from "../hooks/CourseContext";
import classNames from "classnames";
import { UserContext } from "../hooks/UserContext";
import SchedulePayment from "./SchedulePayment";

export default function ScheduleList({ courses, ...rest }) {
  return courses.map(course => <ScheduleItem course={course} {...rest} />)
}

function ScheduleItem(props) {
  const { course, cancelRegisterClicked } = props
  const { user } = useContext(UserContext)

  const schedules = course.schedules.filter(schedule => 
    schedule.status === COURSE_SCHEDULE_STATUS_ONGOING || 
    schedule.status === COURSE_SCHEDULE_STATUS_DUED
  )

  return schedules.map(schedule => (
    <a class="list-group-item list-group-item-action" aria-current="true">
      <div class="d-flex justify-content-between">
        <div class="scheduel-title">{course.title}</div>
        <div class={classNames('badge', { 
          'bg-success': schedule.status === COURSE_SCHEDULE_STATUS_ONGOING,
          'bg-secondary': schedule.status === COURSE_SCHEDULE_STATUS_DUED ,
        })}>
          {courseScheduleStatusText(schedule.status)}
        </div>
      </div>
      <p class="scheduel-period">เริ่มสอบ {courseDatesFormat(schedule).examDateRange}</p>
      {user && <SchedulePayment user={user} schedule={schedule} cancelRegisterClicked={cancelRegisterClicked} />}
    </a>
  ))
}
