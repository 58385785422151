import { ADMIN_PHONE_NUMBER } from "../_constant";
import Image from "../components/Image";
import PageTitle from "../components/PageTitle";

export default function Contact() {
  return (
    <>
      <div class="page page-contact">
        <PageTitle title={'ติดต่อ'} backTo={'/'} />
        <div class="small-text">ติดต่อสอบถามแอดมินได้ทาง LINE <br /> หรือโทร {ADMIN_PHONE_NUMBER}</div>

        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                เอกสารไปนอก
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                - ภาพถ่าย 2 นิ้ว พื้นหลังขาว 2 ใบ <br />
                - สำเนาบัตรประชาชน 2 ชุด <br />
                - สำเนาทะเบียนบ้าน 2 ชุด <br />
                <Image src="faq_man_photo.jpg" alt="preview-photo" /> <br />
                * ตัวอย่างภาพถ่าย
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}