import { useContext } from "react";
import Button from "../components/Button";
import { toast } from "react-toastify";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useTutorialContext } from "../hooks/TutorialContext";

export default function Register() {
  const { registerUser, registerForm: form, setRegisterForm: setForm, userFormValidate } = useContext(UserContext)
  const navigate = useNavigate()
  const { setTutorialStep } = useTutorialContext()

  const createProfileClicked = () => {
    registerUser()
      .then((res) => {
        toast(`ยินดีต้อนรับ! คุณ ${res.data.user.name}`)
        setForm({})
        setTutorialStep(1)
        navigate('/handbook')
      })
      .catch(e => e)
  }

  const formInputChanged = (e) => {
    const { name, val, isValid } = userFormValidate(e.target.name, e.target.value)

    if (isValid) {
      setForm({ ...form, 
        [name]: val
      })
    }
  }

  const termOfServiceClicked = () => {
    navigate('/term-of-service')
  }

  return (
    <>
      <div class="page page-register">
        <PageTitle title={'สมัครสมาชิก'} backTo={'/login'} />

        <form>
          <div class="form-group">
            <label>ชื่อ-นามสกุล</label>
            <input name="name" type="text" class="form-control" value={form.name} onChange={formInputChanged} />
          </div>
          <div class="form-group">
            <label>เบอร์โทรศัพท์</label>
            <input name="phone_number" type="text" class="form-control" value={form.phone_number} onChange={formInputChanged} />
          </div>
          <div class="form-group">
            <label>อายุ</label>
            <input name="age" type="text" class="form-control" value={form.age} onChange={formInputChanged} />
          </div>

          <Button onClick={createProfileClicked}>ยืนยัน</Button>
          <div class="small-text">ในการกดปุ่ม "ยืนยัน" การสมัครสมาชิก คุณต้องยินยอม <span onClick={termOfServiceClicked}>เงื่อนไขในการให้บริการ</span></div>
        </form>
      </div>
    </>
  )
}