import { useState } from "react"

const ANTI_SPAM_TIMER = 1000

export default function Button({ onClick, disabled = false, className = "", overwriteStyle = false, children, ...rest }) {
  const [allowClick, setAllowClick] = useState(true)
  let btnStyleClass = disabled 
    ? 'btn-secondary ' 
    : 'btn-primary '


  if (! overwriteStyle) {
    btnStyleClass += disabled 
      ? 'single-page-secondary-button' 
      : 'single-page-button'
  }

  const handleClicked = (e) => {
    if (allowClick) {
      onClick(e)
      setAllowClick(false)
      setTimeout(() => { setAllowClick(true) }, ANTI_SPAM_TIMER)
    }
  }
  
  return (
    <button type="button" class={`btn button ${btnStyleClass} ${className}`} onClick={handleClicked} {...rest}>{children}</button>
  )
}