import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Course from "./pages/Course";
import CourseDetail from "./pages/CourseDetail";
import Quiz from "./pages/Quiz";
import UserProvider from "./hooks/UserContext";
import CourseProvider from "./hooks/CourseContext";
import Home from "./pages/Home";
import Payment from "./pages/Payment";
import PaymentProvider from "./hooks/PaymentContext";
import Schedule from "./pages/Schedule";
import Account from "./pages/Account";
import { ToastContainer } from 'react-toastify';
import TermOfService from "./pages/TermOfService";
import Handbook from "./pages/Handbook";
import QuizExam from "./pages/QuizExam";
import QuizProvider from "./hooks/QuizContext";
import Contact from "./pages/Contact";
import ProtectedRoute from "./components/ProtectedRoute";
import TutorialProvider from "./hooks/TutorialContext";

function App() {
  return (
    <div class="main-container">
      <div class="col">
        <div class="content">
        
          <TutorialProvider>
            <QuizProvider>
              <PaymentProvider>
                <UserProvider>
                  <CourseProvider>

                    <BrowserRouter>
                      <Routes>
                        <Route path="/">
                          <Route index element={<Home />} />
                          <Route path="/register" element={<Register />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/course/:id" element={<CourseDetail />} />
                          <Route path="/course" element={<Course />} />
                          <Route path="/term-of-service" element={<TermOfService />} />
                          <Route path="/handbook" element={<Handbook />} />
                          <Route path="/contact" element={<Contact />} />
                          <Route path="/schedule" element={<Schedule />} />

                          <Route element={<ProtectedRoute/>}>
                            <Route path="/payment" element={<Payment/>}/>
                            <Route path="/account" element={<Account />} />
                            <Route path="/course/:courseId/quiz" element={<Quiz />} />
                            <Route path="/course/:courseId/quiz/:quizId" element={<QuizExam />} />
                          </Route>

                        </Route>
                      </Routes>
                    </BrowserRouter>

                  </CourseProvider>
                </UserProvider>
              </PaymentProvider>
            </QuizProvider>
          </TutorialProvider>

          <ToastContainer position="bottom-right" autoClose={4000} />

        </div>
      </div>
    </div>
  );
}

export default App;
