import ApiClient from "../utils/request";

export function getCourses() {
  return ApiClient.get('/courses')
}

export function getCourse(id) {
  return ApiClient.get(`/courses/${id}`)
}

export function registerCourse(id) {
  return ApiClient.post(`/courses/${id}/register`)
}

export function cancelRegisterCourse(id, course_schedule_id) {
  return ApiClient.post(`/courses/${id}/register/cancel`, { course_schedule_id })
}