import { useNavigate } from "react-router-dom"
import Image from "./Image"

export default function HomeFooter() {
  const navigate = useNavigate()

  const homeClicked = () => {
    navigate('/')
  }

  const handbookClicked = () => {
    navigate('/handbook')
  }

  const accountClicked = () => {
    navigate('/account')
  }

  return (
    <footer class="footer fixed-bottom">
      <div class="footer-menu" onClick={homeClicked}>
        <Image className="footer-menu-icon" src="home_icon.png" alt="หน้าแรก" />
        <div class="footer-label">หน้าแรก</div>
      </div>
      <div class="footer-menu" onClick={handbookClicked}>
        <Image className="footer-menu-icon" src="search_icon.png" alt="คู่มือใช้งาน" />
        <div class="footer-label">คู่มือใช้งาน</div>
      </div>
      <div class="footer-menu" onClick={accountClicked}>
        <Image className="footer-menu-icon" src="user_icon.png" alt="บัญชี" />
        <div class="footer-label">บัญชี</div>
      </div>
    </footer>
  )
}