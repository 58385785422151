import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UserContext } from "../hooks/UserContext"
import PageTitle from "../components/PageTitle"
import Button from "../components/Button"
import { handleHttpErrors } from "../utils/errorHandler"
import { toast } from "react-toastify"
import { QUIZ_ATTEMPT_STATUS_PASSED, QuizContext } from "../hooks/QuizContext"
import { registerCourse } from "../services/course"
import { useTutorialContext } from "../hooks/TutorialContext"

export default function Quiz() {
  const [isLoaded, toggleIsLoaded] = useState(false)
  const { courseId } = useParams()
  const { quizzes, fetchQuizzes } = useContext(QuizContext)
  const { user, fetchUserProfile } = useContext(UserContext)
  const { showTutorialStep } = useTutorialContext()
  const navigate = useNavigate()

  const passedQuizIds = isLoaded ? user.quiz_attempts.filter(quiz => quiz.pivot.status === QUIZ_ATTEMPT_STATUS_PASSED).map(quiz => quiz.id) : []
  const allowRegister = isLoaded && quizzes.every(quiz => passedQuizIds.includes(quiz.id))

  useEffect(() => {
    fetchData()

  }, [])

  useEffect(() => {
    if (isLoaded) {
      showTutorialStep(3)
    }
  }, [isLoaded])

  const fetchData = async () => {
    try {
      await fetchQuizzes(courseId)
      await fetchUserProfile()
      toggleIsLoaded(true)
    } 
    catch (e) {
      console.error(e)
    }
  }

  const startQuizClicked = (id) => {
    if (! passedQuizIds.includes(id)) {
      navigate(`/course/${courseId}/quiz/${id}`)
    }
  }

  const courseRegisterClicked = () => {
    if (allowRegister) {
      registerCourse(courseId)
        .then(res => {
          toast('ลงทะเบียนสำเร็จ')
          navigate('/')
        })
        .catch(handleHttpErrors)
    }
  }

  return (
    <>
      <div class="page page-quiz">
        <PageTitle title={'แบบทดสอบ'} backTo={`/course/${courseId}`} />

        <div id="tutorial-3-1">
          <QuizList quizzes={quizzes} isLoaded={isLoaded} passedQuizIds={passedQuizIds} startQuizClicked={startQuizClicked} />
        </div>

        <Button id="tutorial-3-2" disabled={! allowRegister} className="position-bottom" onClick={courseRegisterClicked}>ลงทะเบียน</Button>
      </div>
    </>
  )
}

function QuizList(props) {
  const { quizzes, isLoaded, passedQuizIds, startQuizClicked } = props
  const isPassed = (quiz) => passedQuizIds.includes(quiz.id)

  return quizzes.map(quiz => (
    <div class="quiz-list">
      <div>{quiz.title}</div>
      <div class="small-text">เกณฑ์การผ่าน ตอบถูก {quiz.pass_score} ข้อ จาก {quiz.questions.length} ข้อ</div>

      {isLoaded && <>
        {isPassed(quiz) ?
          <button class="btn btn-outline-primary" disabled>
            สอบผ่านแล้ว
          </button> 
          : 
          <button class="btn btn-primary" onClick={() => startQuizClicked(quiz.id)}>
            ทำแบบทดสอบ
          </button>
        }
      </>}
    </div>
  ))
}