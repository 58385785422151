import { driver } from 'driver.js';

function createDriver(driverStep = {}) {
  if (! Array.isArray(driverStep)) {
    driverStep = [driverStep]
  }

  const driverObj = driver({
    showProgress: true,
    showButtons: ['next'],
    doneBtnText: 'ตกลง',
    nextBtnText: 'ตกลง',
    disableActiveInteraction: true,
    steps: driverStep
  });
  driverObj.drive()
}

function createDriverStep(elementSelector, title, desc) {
  return {
    element: elementSelector,
    popover: {
      title: title,
      description: desc,
      position: 'bottom'
    }  
  }
}

export const showTutorial = (elementSelector = "#element", title = '', desc = '') => {
  createDriver(createDriverStep(elementSelector, title, desc))
}

export const showTutorials = (firstStep = ['elementSelector', 'title', 'desc'], ...restSteps) => {
  const _steps = [firstStep, ...restSteps].map((step) => {
    const [elementSelector, title, desc] = step
    return createDriverStep(elementSelector, title, desc)
  })

  createDriver(_steps)
}