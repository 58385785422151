import { useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useContext, useState } from "react";
import { UserContext } from "../hooks/UserContext";
import { toast } from "react-toastify";
import Button from "../components/Button";

export default function Login() {
  const navigate = useNavigate()
  const { login } = useContext(UserContext)
  const [phoneNumber, setPhoneNumber] = useState(null)

  const loginButtonClicked = async () => {
    try {
      const res = await login({ phoneNumber })
      toast(`ยินดีต้อนรับ! คุณ ${res.data.name}`)
      navigate('/')
    }
    catch (e) {
      console.error(e)
      if (e.response?.status === 401) {
        toast(`ไม่พบผู้ใช้งาน กรุณาสมัครสมาชิก`)
      }
    }
  }

  const registerButtonClicked = () => {
    navigate('/register')
  }

  const inputChanged = (e) => {
    setPhoneNumber(e.target.value)
  }

  return (
    <>
      <div class="page page-login-top">
        <PageTitle title="เข้าสู่ระบบ" backTo={'/'} />
    
        <div class="form-group">
          <label>เบอร์โทรศัพท์</label>
          <input type="text" class="form-control" onChange={inputChanged} />
        </div>
        <Button className="btn btn-primary single-page-button" onClick={loginButtonClicked}>เข้าสู่ระบบ</Button>
      </div>

      <div class="page page-login-bottom">
        <div class="login-bottom">
          <div class="small-text">ยังไม่ได้เป็นสมาชิก?</div>
          <Button className="btn btn-primary single-page-button" onClick={registerButtonClicked}>สมัครสมาชิก</Button>
        </div>
      </div>
    </>
  )
}