import React, { createContext, useMemo, useState } from 'react';
import { getCourses } from '../services/course';
import dayjs from '../utils/day'
import { handleHttpErrors } from '../utils/errorHandler';

export const CourseContext = createContext();

const CourseProvider = ({ children }) => {
  const [courses, setCourses] = useState([]);
  const [coursesLoaded, toggleCoursesLoaded] = useState(false);

  const learningCourses = useMemo(
    () => courses.filter(course => course.type === COURSE_TYPE_LEARNING),
    [courses]
  )

  const certificateCourses = useMemo(
    () => courses.filter(course => course.type === COURSE_TYPE_CERTIFICATE),
    [courses]
  )

  const fetchCourses = async () => {
    return getCourses()
      .then(res => {
        setCourses(res.data)
        toggleCoursesLoaded(true)
      })
      .catch(handleHttpErrors)
  }

  return (
    <CourseContext.Provider value={{ courses, learningCourses, certificateCourses, coursesLoaded, fetchCourses }}>
      {children}
    </CourseContext.Provider>
  );
};

export default CourseProvider;

export const COURSE_SCHEDULE_STATUS_ONGOING = 'ongoing'
export const COURSE_SCHEDULE_STATUS_DUED = 'dued'


export const COURSE_TYPE_LEARNING = 'learning';
export const COURSE_TYPE_CERTIFICATE = 'certificate';

export function courseDatesFormat(schedule) {
  let openRegisterDate = null
  let examDateRange = ''
  let isReadyToRegister = false
  
  if (schedule) {
    openRegisterDate = dayjs(schedule.open_register_date)
    const startDate = dayjs(schedule.start_date)
    const endDate = dayjs(schedule.end_date)
    
    isReadyToRegister = openRegisterDate.isBefore(dayjs())
    openRegisterDate = isReadyToRegister ? 'สมัครเลยตอนนี้ >>' : `เปิดรับสมัคร ${openRegisterDate.format('MMM D, YYYY')}`
    examDateRange = `${startDate.format('D MMM')} - ${endDate.format('D MMM YYYY')}`
  }

  return { isReadyToRegister, openRegisterDate, examDateRange }
}

export const courseScheduleStatusText = (status) => {
  switch(status) {
    case COURSE_SCHEDULE_STATUS_ONGOING:
      return 'เปิดรับสมัคร'
    case COURSE_SCHEDULE_STATUS_DUED:
      return 'เสร็จสิ้น'
    default:
      return ''
  }
}