import { useNavigate } from 'react-router-dom'
import toMoney from '../utils/moneyFormat'
import { COURSE_SCHEDULE_STATUS_ONGOING, courseDatesFormat } from '../hooks/CourseContext'
import Image from './Image'

export default function CourseCard({ course, ...rest }) {
  const schedule = course.schedules.find(_schedule => _schedule.status === COURSE_SCHEDULE_STATUS_ONGOING)
  const { openRegisterDate, examDateRange } = courseDatesFormat(schedule)
  const price = toMoney(course.price)
  const navigate = useNavigate()

  const courseClicked = () => {
    navigate(`/course/${course.id}`)
  }

  return (
    <div class="course-card" onClick={courseClicked} {...rest}>
      <div class="img-container">
        <Image src={course.img_src} alt={course.title} />
      </div>
      <div class="course-card-detail">
        <div class="course-title">{course.title}</div>
        {examDateRange && <div class="course-date">เริ่มสอบ {examDateRange}</div>}
        <div class="course-place">โรงเรียนพัฒนาอาชีพ อุดรธานี</div>
        <div class="course-price">{price}</div>
        <div class="course-text">{openRegisterDate}</div>
      </div>
    </div>
  )
}