import { useContext, useEffect, useState } from "react";
import VanillaCalendar, { defaultConfig } from "../components/VanillaCalendar";
import PageTitle from "../components/PageTitle";
import { CourseContext } from "../hooks/CourseContext";
import { UserContext } from "../hooks/UserContext";
import { Modal } from 'react-responsive-modal';
import Button from "../components/Button";
import { cancelRegisterCourse } from "../services/course";
import { toast } from "react-toastify";
import { handleHttpErrors } from "../utils/errorHandler";
import ScheduleList from "../components/ScheduleList";

export default function Schedule() {
  const [selectedSchedule, selectSchedule] = useState(false)
  const [isModalOpened, toggleModal] = useState(false)
  const { fetchUserProfile } = useContext(UserContext)
  const { coursesLoaded, courses, fetchCourses } = useContext(CourseContext)

  useEffect(() => {
    if (! coursesLoaded) {
      fetchCourses()
    }
    fetchUserProfile().catch(console.error)
  }, [])

  const examDates = courses
    .flatMap(course => course.schedules)
    .flatMap(schedule => `${schedule.start_date}:${schedule.end_date}`)

  const cancelRegisterClicked = (courseSchedule) => {
    toggleModal(true)
    selectSchedule(courseSchedule)
  }

  const cancelRegisterConfirmClicked = () => {
    cancelRegisterCourse(selectedSchedule.course_id, selectedSchedule.id)
      .then(() => {
        toast('ยกเลิกการลงทะเบียนสำเร็จ')
        fetchUserProfile().catch(console.error)
      })
      .catch(handleHttpErrors)
      .finally(() => toggleModal(false))
  }

  return (
    <>
      <div class="page page-schedule">
        <PageTitle title={'ตารางสอบ'} backTo={'/'} />
        <VanillaCalendar config={defaultConfig(examDates)} />

        <div class="list-group schedule-list">
          <ScheduleList courses={courses} cancelRegisterClicked={cancelRegisterClicked} />
        </div>
      </div>

      <Modal open={isModalOpened} onClose={() => toggleModal(false)} center>
        <h2>ยกเลิกการลงทะเบียนนี้?</h2>
        <Button overwriteStyle className="btn-danger cancel-register-confirm-button" onClick={cancelRegisterConfirmClicked}>ยืนยัน</Button>
      </Modal>
    </>
  )
}