import { useContext, useEffect } from "react";
import CourseCard from "../components/CourseCard";
import { COURSE_TYPE_CERTIFICATE, COURSE_TYPE_LEARNING, CourseContext } from "../hooks/CourseContext";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import HomeFooter from "../components/HomeFooter";
import Image from "../components/Image";
import { useTutorialContext } from "../hooks/TutorialContext";

export default function Home() {
  const { coursesLoaded, courses, fetchCourses } = useContext(CourseContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const { showTutorialStep } = useTutorialContext()

  useEffect(() => {
    if (! coursesLoaded) {
      fetchCourses()
    }
  }, [])

  useEffect(() => {
    if (coursesLoaded) {
      showTutorialStep(1)
      showTutorialStep(4)
    }
  }, [coursesLoaded])

  const certificateCourseClicked = () => {
    navigate(`/course?type=${COURSE_TYPE_CERTIFICATE}`)
  }

  const learningCourseClicked = () => {
    navigate(`/course?type=${COURSE_TYPE_LEARNING}`)
  }

  const paymentClicked = () => {
    navigate('/payment')
  }

  const scheduleClicked = () => {
    navigate('/schedule')
  }

  const contactClicked = () => {
    navigate('/contact')
  }

  return (
    <>
      <div class="page page-home">
        <div class="account-name">ยินดีต้อนรับ {user?.name}</div>
        <div class="upper-menu-wrapper">
          <div>
            <div class="menu-button" onClick={certificateCourseClicked}>
              <div>สอบใบอนุญาติ</div>
              <Image className="upper-menu-1st-image" src="excavator_logo.jpg" url="สอบใบอนุญาติ" />
            </div>
          </div>
          <div>
            <div class="menu-button" onClick={learningCourseClicked}>
              <div>คอร์สเรียน</div>
              <Image className="upper-menu-2nd-image" src="worker_logo.png" url="คอร์สเรียน" />
            </div>
          </div>
        </div>
        <div class="lower-menu-wrapper">
          <div>
            <div id="tutorial-4-1" class="menu-button" onClick={paymentClicked}> 
              <Image class="lower-menu-image" src="payment_logo.jpg" url="ชำระเงิน" />
            </div>
          </div>
          <div>
            <div id="tutorial-4-2" class="menu-button" onClick={scheduleClicked}> 
              <Image class="lower-menu-image-2" src="calendar_logo.jpg" url="ปฏิทิน" />
            </div>
          </div>
          <div>
            <div id="tutorial-4-3" class="menu-button" onClick={contactClicked}> 
              <Image class="lower-menu-image-3" src="contact_admin_logo.jpg" url="ติดต่อ" />
            </div>
          </div>
        </div>
        <div class="lower-menu-text-wrapper">
          <div>ชำระเงิน</div>
          <div>ปฏิทิน</div>
          <div>ติดต่อ</div>
        </div>
        <div id="tutorial-1" class="course-list">
          <div class="course-list-title">ลงทะเบียนวันนี้</div>
          {courses.map((course, i) => <CourseCard course={course} />)}
        </div>
      </div>

      <HomeFooter />
    </>
  )
}