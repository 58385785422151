import { useContext, useEffect } from "react";
import BackButton from "../components/BackButton";
import { PAYMENT_STATUS_CANCELED, PAYMENT_STATUS_PAID, PAYMENT_STATUS_WAIT_USER_PAY, PaymentContext, paymentStatusText } from "../hooks/PaymentContext";
import toMoney from "../utils/moneyFormat";
import classNames from "classnames";
import PageTitle from "../components/PageTitle";
import { courseDatesFormat } from "../hooks/CourseContext";
import Image from "../components/Image";

export default function Payment() {
  const { fetchPayments, payments } = useContext(PaymentContext)

  useEffect(() => {
    fetchPayments()
  }, [])

  const paymentClicked = (payment) => {}

  return (
    <>
      <div class="page page-payment">
        <PageTitle title={'ชำระเงิน'} backTo={'/'} />
        <div class="small-text">หากเลือกชำระเงินผ่านธนาคาร ผู้สมัครต้องส่งสลิปการโอนผ่าน LINE</div>
        <div class="payment-methods">
          <div class="small-text">*** ผู้สมัครสามารถชำระเป็นเงินสดได้ที่โรงเรียน *** <br /> หรือ ชำระเงินผ่านช่องทางธนาคาร </div>
          <Image className="payment-logo" src="kbank_logo.jpg" alt="kbank" />
          <Image className="payment-logo" src="scb_logo.jpg" alt="scb" />
        </div>
        {payments.map(payment => <PaymentCard payment={payment} paymentClicked={paymentClicked} />)}
      </div>
    </>
  )
}

function PaymentCard(props) {
  const { payment, paymentClicked } = props
  const course = payment.schedule.course
  const { examDateRange } = courseDatesFormat(payment.schedule)
  const amount = toMoney(payment.amount)

  return(
    <div class="payment-card" onClick={() => paymentClicked(payment)}>

      <div class="payment-card-inner-wrapper">
        <div class="payment-card-detail-left">
          <Image src={course.img_src} alt={course.title} />
        </div>
        <div class="payment-card-detail-right">
          <div class="payment-title">{course.title}</div>
          {examDateRange && <div class="payment-date">เริ่มสอบ {examDateRange}</div>}
          <div class={classNames('payment-status', { 
            'orange': payment.status === PAYMENT_STATUS_WAIT_USER_PAY,
            'green': payment.status === PAYMENT_STATUS_PAID ,
            'blue': payment.status === PAYMENT_STATUS_CANCELED
          })}>
            {paymentStatusText(payment.status)}</div>
          <div class="payment-price">{amount}</div>
        </div>
      </div>
      
      {payment.status === PAYMENT_STATUS_WAIT_USER_PAY && (
        <>
         <hr />
         <div class="payment-footer">
          <button type="button" class="btn btn-outline-success payment-button">ส่งสลิปไปยัง LINE</button>
        </div>
        </>
       )}
    </div>
  )
}