import { useParams } from "react-router";
import BackButton from "../components/BackButton";
import ImageGallery from "react-image-gallery";
import { useContext, useEffect } from "react";
import { COURSE_SCHEDULE_STATUS_ONGOING, CourseContext, courseDatesFormat } from "../hooks/CourseContext";
import toMoney from "../utils/moneyFormat";
import { useNavigate } from "react-router-dom"
import { SERVER_ENDPOINT } from "../_constant";
import { useTutorialContext } from "../hooks/TutorialContext";

export default function CourseDetail() {
  const { id } = useParams();
  const { coursesLoaded, courses, fetchCourses } = useContext(CourseContext)
  const course = courses.find(_course => _course.id.toString() === id)
  const schedule = course?.schedules?.find(_schedule => _schedule.status === COURSE_SCHEDULE_STATUS_ONGOING)
  const { openRegisterDate, examDateRange, isReadyToRegister } = courseDatesFormat(schedule)
  const navigate = useNavigate()
  const { showTutorialStep } = useTutorialContext()

  useEffect(() => {
    if (! coursesLoaded) {
      fetchCourses()
    }
  }, [])
  
  useEffect(() => {
    if (coursesLoaded) {
      showTutorialStep(2)
    }
  }, [coursesLoaded])

  const courseRegisterClicked = () => {
    navigate(`/course/${id}/quiz`)
  }

  if (!coursesLoaded || !course)
    return null

  return (
    <>
      <div class="page page-course-detail">
        <ImageGallery items={insertUiGallery(course)} loading={"lazy"} />
        <div class="float-back-button-wrapper">
          <BackButton backTo={`/course?type=${course.type}`} />
        </div>

        <div id="tutorial-2-1" class="course-detail">
          <div class="title">{course.title}</div>
          <div class="label">คุณสมบัติ</div> <div>อายุ {course.age_requirement}</div>
          {examDateRange && <><div class="label">ตารางเวลา</div> <div>เริ่มสอบวันที่ {examDateRange}</div></>}
          <div class="label">สถานที่</div> <div>{course.place}</div>
          <div class="label">รายละเอียด</div> <div>{course.description}</div>
          <div class="label">ราคา</div> <div class="course-price">{toMoney(course.price)}</div>

          <div class="button-wrapper">
            {isReadyToRegister 
              ? <button id="tutorial-2-2" type="button" class="btn btn-primary single-page-button" onClick={courseRegisterClicked}>ลงทะเบียน</button>
              : <button id="tutorial-2-2" type="button" class="btn btn-secondary single-page-secondary-button"> {openRegisterDate ? `${openRegisterDate}` : 'รอติดตามข่าวสารผ่าน LINE'}</button>}
          </div>
        </div>
      </div>
    </>
  )
}

const insertUiGallery = (course) => {
  if (! course)
    return []

  let images = [{ 
    original: `${SERVER_ENDPOINT}/img/${course.img_src}`,
    thumbnail: `${SERVER_ENDPOINT}/img/${course.img_src}`,
  }]
  const subImages = course.images.map(image => ({
    original: `${SERVER_ENDPOINT}/img/${image.img_src}`,
    thumbnail: `${SERVER_ENDPOINT}/img/${image.img_src}`,
  })) 
  images = [...images, ...subImages]

  return images
}
