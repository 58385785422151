import React, { createContext, useEffect, useState } from 'react';
import { getMe, createUser, login as APILogin, logout as APILogout, updateMe } from '../services/user';
import { handleHttpErrors } from '../utils/errorHandler';
import { removeToken, setToken } from '../utils/request';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [authChecked, toggleAuthChecked] = useState(false);
  const [user, setUser] = useState(null);
  const [registerForm, setRegisterForm] = useState({ name: '', phone_number: '', age: '' });

  const registerUser  = async () => {
    try {
      const res = await createUser(registerForm)
      const { token } = res.data
      setToken(token)
      await fetchUserProfile()
      return res
    } 
    catch (e) {
      handleHttpErrors(e)
      throw e
    }
  }

  const fetchUserProfile = async () => {
    return getMe()
      .then(res => {
        toggleAuthChecked(true)
        if (user) setUser({ ...user, ...res.data })
        else setUser(res.data)
        
        return res
      })
      .catch((e) => {
        toggleAuthChecked(true)
        handleHttpErrors(e)
        throw (e)
      })
  }

  const updateUserProfile = async ({ name, phone_number, age }) => {
    try {
      const res = await updateMe({ name, phone_number, age })
      if (user) setUser({ ...user, ...res.data })
      else setUser(res.data)

      return res
    } 
    catch (e) {
      handleHttpErrors(e)
      throw e
    }
  }

  const login = async ({ phoneNumber }) => {
    try {
      const res = await APILogin({ phone_number: phoneNumber })
      const { access_token } = res.data
      setToken(access_token)
      const res2 = await fetchUserProfile()
      return res2
    }
    catch (e) {
      handleHttpErrors(e)
      throw e
    }
  }

  const logout = async () => {
    try {
      await APILogout()
      setUser(null)
      removeToken()
    }
    catch (e) {
      handleHttpErrors(e)
      throw e
    }
  }

  const userFormValidate = (name, val) => {
    val = val.replaceAll('-', '')
    const numberOnlyRegex = /^\d+$/;
    const isNumberOnly = numberOnlyRegex.test(val)
    let isValid = true

    if (name === 'phone_number' && !isNumberOnly)
      isValid = false

    if (name === 'age') {
      if (!isNumberOnly && val !== '')
        isValid = false
      
      if (val !== '')
        val = parseInt(val, 10)
    }

    return { name, val, isValid }
  }

  useEffect(() => {
    fetchUserProfile().catch(console.error)
  }, [])

  return (
    <UserContext.Provider 
      value={{ 
        user, fetchUserProfile, registerUser, registerForm, setRegisterForm, 
        authChecked, login, logout, updateUserProfile, userFormValidate
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;