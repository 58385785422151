import axios from 'axios';
import { API_ENDPOINT } from '../_constant';

// Create an Axios instance
const ApiClient = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 5000,
});

ApiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  }
)

ApiClient.interceptors.response.use(
  response => response,
  error => {
    console.error('Server responded with an error:', error.response.data);
    console.log(error)
    return Promise.reject(error);
  }
);

export const setToken = (token) => {
  localStorage.setItem('authToken', token);
}

export const removeToken = () => {
  localStorage.removeItem('authToken');
}

export const authHeader = () => {
  const token = localStorage.getItem('authToken');
  return { headers: {"Authorization" : `Bearer ${token}`} }
} 

export default ApiClient;