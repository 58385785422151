import classNames from "classnames";
import { PAYMENT_STATUS_PAID, PAYMENT_STATUS_WAIT_USER_PAY } from "../hooks/PaymentContext";
import { useNavigate } from "react-router-dom";

export default function SchedulePayment(props) {
  const { user, schedule, cancelRegisterClicked } = props
  const navigate = useNavigate()
  
  const payments = user.payments.filter(payment => payment.course_schedule_id === schedule.id)
  const pendingPayment = payments.find(payment => payment.status === PAYMENT_STATUS_WAIT_USER_PAY)
  const paidPayment = payments.find(payment => payment.status === PAYMENT_STATUS_PAID)

  const paymentClicked = () => {
    navigate('/payment')
  }

  if (! pendingPayment && ! paidPayment)
    return null

  return (
    <div class="action-wrapper">
      <div class="action-status-wrapper">
        <div>ลงทะเบียนแล้ว</div> 
        <div class={classNames('small-text', { orange: pendingPayment })}>{pendingPayment ? 'ยังไม่ชำระ' : 'ชำระเงินแล้ว'}</div> 
      </div>
      <div class="action-buttons-wrapper">
        {pendingPayment && (
          <>
            <button class="btn btn-outline-secondary btn-sm cancel-button" onClick={() => cancelRegisterClicked(schedule)}>ยกเลิก</button>
            <button class="btn btn-outline-success btn-sm payment-button" onClick={paymentClicked}>ชำระเงิน</button>
          </>
        )}
      </div>
    </div>
  )
}