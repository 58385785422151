import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QuizContext } from "../hooks/QuizContext";
import Button from "../components/Button";
import { attemptQuiz } from "../services/quiz";
import { toast } from "react-toastify";
import { UserContext } from "../hooks/UserContext";
import { handleHttpErrors } from "../utils/errorHandler";
import PageTitle from "../components/PageTitle";
import Image from "../components/Image";

export default function QuizExam() {
  const [finished, toggleFinish] = useState(false)
  const [quiz, setQuiz] = useState(null)
  const [question, setQuestion] = useState(null)
  const [score, setScore] = useState(0)
  const [allowToFinish, toggleAllowToFinish] = useState(false)
  const [clickedChoiceId, setClickedChoiceId] = useState(null)
  const [selectedChoiceIds, setSelectedChoiceIds] = useState([])

  const { courseId, quizId } = useParams()
  const { quizzes } = useContext(QuizContext)
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  const questionIndex = question ? quiz.questions.findIndex(q => q.id === question.id) : 0

  useEffect(() => {
    const _quiz = quizzes.find(_quiz => _quiz.id === parseInt(quizId))
    setQuiz(_quiz)
    setQuestion(_quiz.questions[0])
  }, [quizzes])

  useEffect(() => {
    if (finished) {
      attemptQuiz({ quizId: quiz.id, userId: user.id, score })
        .then(res => {
          if (score >= quiz.pass_score) {
            toast('คุณผ่านการทดสอบ')
            toggleAllowToFinish(true)
          } else {
            toast('คุณไม่ผ่านการทดสอบ กรุณาลองใหม่')
          }
        })
        .catch(handleHttpErrors)
    }
  }, [finished])

  const calculateScore = (choiceIds) => {
    return quiz.questions
      .flatMap(q => q.choices)
      .filter(choice => choice.is_correct_choice && choiceIds.includes(choice.id))
      .length
  }

  const choiceClicked = (choice) => {
    setClickedChoiceId(choice.id)
  }

  const giveAnswerClicked = () => {
    if (!clickedChoiceId) 
      return false
    
    const choiceIds = [...selectedChoiceIds, clickedChoiceId]
    setSelectedChoiceIds(choiceIds)
    setClickedChoiceId(null)

    const isItLastQuestion = questionIndex+1 === quiz.questions.length

    if (isItLastQuestion) {
      toggleFinish(true)
      const _score = calculateScore(choiceIds)
      setScore(_score)
    } else {
      setQuestion(quiz.questions[questionIndex+1])
    }
  }

  const restartQuizClick = () => {
    toggleFinish(false)
    setQuestion(quiz.questions[0])
    setSelectedChoiceIds([])
  }

  const nextClicked = () => {
    navigate(`/course/${courseId}/quiz`)
  }

  return (
    <>
      <div class="page page-exam">
        <PageTitle title={quiz?.title} backTo={`/course/${courseId}/quiz`} />

        {/* EXAM PAGE */}
        {!finished && question && (
          <>
            <div class="question">
              {questionIndex+1}. {question.question_text}
            </div>
            <ChoiceList choiceClicked={choiceClicked} clickedChoiceId={clickedChoiceId} choices={question.choices} />

            <div class="next-question-wrapper">
              <Button className="next-question" onClick={giveAnswerClicked}>เลือก</Button>
            </div>
          </>
        )}

        {/* RESULT PAGE */}
        {finished && (
           <>
            <div class="result-wrapper">
              <div class="result-title">จบการทดสอบ</div>
              <div class="result-detail">
                คุณตอบถูก {score} ข้อ จากทั้งหมด {quiz.questions.length} ข้อ <br />
                เกณฑ์การผ่านแบบทดสอบ {quiz.pass_score} ข้อ
              </div>
              
              {score >= quiz.pass_score && (
                <>
                  <Image className="result-img" src="success.jpg" alt="success" />
                  <div class="result-pass">คุณผ่านการทดสอบ</div>
                </>
              )}
              {score < quiz.pass_score && (
                <>
                  <Image className="result-img" src="fail.jpg" alt="fail" />
                  <div class="result-fail">คุณไม่ผ่านการทดสอบ</div>
                </>
              )}
            </div>
            {score >= quiz.pass_score && allowToFinish && <Button onClick={nextClicked}>ถัดไป</Button>}
            {score < quiz.pass_score && <Button onClick={restartQuizClick}>ลองอีกครั้ง</Button>}
          </>
        )}
      </div>
    </>
  )
}

function ChoiceList(props) {
  const { choices, clickedChoiceId, choiceClicked } = props

  return choices.map((choice) => (
    <div 
      class={classNames("choice-button", { active: clickedChoiceId === choice.id})} 
      onClick={() => choiceClicked(choice)}
    >
      {choice.choice_text}
    </div>)
  )
}