import React, { createContext, useContext, useState } from 'react';
import { showTutorial, showTutorials } from '../utils/tutorial';
import { ADMIN_PHONE_NUMBER } from '../_constant';

export const TutorialContext = createContext();

const TutorialProvider = ({ children }) => {
  const [tutorialStep, setTutorialStep] = useState(0);

  const showTutorialStep = (step, cof={}) => {
    if (!tutorialStep)
      return false

    if (tutorialStep === 1 && step === 1) {
      showTutorial('#tutorial-1', 'เลือกคอร์สที่ต้องการ', 'เลือกคอร์สเรียนหรือคอร์สสอบใบอนุญาตที่ท่านต้องการลงทะเบียน')
      setTutorialStep(2)
    }
    if (tutorialStep === 2 && step === 2) {
      showTutorials(
        ['#tutorial-2-1', 'ตรวจสอบรายละเอียด', 'ตรวจสอบรายละเอียดของคอร์สให้ถี่ถ้วนก่อนลงทะเบียน'], 
        ['#tutorial-2-2', 'ลงทะเบียน', `หากท่านต้องการเรียนหรือสอบใบอนุญาตนอกเหนือเวลาที่กำหนดไว้ ท่านสามารถติดต่อแอดมินได้ผ่าน LINE โดยตรง โดยตรง หรือโทร ${ADMIN_PHONE_NUMBER}`]
      )
      setTutorialStep(3)
    }
    if (tutorialStep === 3 && step === 3) {
      showTutorials(
        ['#tutorial-3-1', 'ทำแบบทดสอบออนไลน์', 'ทำแบบทดสอบออนไลน์เพื่อวัดระดับพื้นฐาน ผู้สมัครสามารถลองสอบใหม่กี่ครั้งก็ได้'],
        ['#tutorial-3-2', 'ลงทะเบียน', 'ลงทะเบียนออนไลน์ได้ทันที และรอมาตามเวลานัดหมาย']
      )
      setTutorialStep(4)
    }
    if (tutorialStep === 4 && step === 4) {
      showTutorials(
        ['#tutorial-4-1', 'ลงทะเบียนสำเร็จ', 'ตรวจสอบการชำระเงิน โดยผู้สมัครสามารถชำระเงินผ่านธนาคาร หรือชำระเป็นเงินสดได้ที่โรงเรียน ในวันนัดหมาย'],
        ['#tutorial-4-2', 'ปฏิทิน', `ตรวจสอบปฏิทินเพื่อดูกำหนดการลงทะเบียน คอร์สเรียนและสอบใบอนุญาติ`],
        ['#tutorial-4-3', 'สอบถาม', `หากมีคำถามเพิ่มเติม หรือท่านต้องการเรียนหรือสอบใบอนุญาตนอกเหนือเวลาที่กำหนดไว้ ท่านสามารถติดต่อแอดมินได้ผ่าน LINE โดยตรง หรือโทร ${ADMIN_PHONE_NUMBER}`]
      )
      setTutorialStep(0)
    }
  }

  return (
    <TutorialContext.Provider value={{ showTutorialStep, setTutorialStep }}>
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorialContext =  () => { 
  const context = useContext(TutorialContext)
  return context
}

export default TutorialProvider;