import { useEffect, useRef, useState } from "react";
import VC from "vanilla-calendar-pro";
 
export const defaultConfig = (selectedDates) => ({
  locale: {
    months: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
    weekday: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
  },
  CSSClasses: {
    dayBtnWeekend: 'nope'
  },
  settings: {
    lang: 'define',
    selection: {
      day: false
    },
    selected: {
      dates: selectedDates, // ['2024-09-09', '2024-09-22'],
    },
  }
})

function VanillaCalendar({ config, ...attributes }) {
  const ref = useRef(null);
  const [calendar, setCalendar] = useState(null);
 
  useEffect(() => {
    if (!ref.current) return
    setCalendar(new VC(ref.current, config));
  }, [ref, config])
 
  useEffect(() => {
    if (!calendar) return;
    calendar.init();
  }, [calendar]);
 
  return (
    <div {...attributes} ref={ref}></div>
  )
}
 
export default VanillaCalendar