import React, { createContext, useState } from 'react';
import { getQuizzes } from '../services/quiz';
import { handleHttpErrors } from '../utils/errorHandler';

export const QuizContext = createContext();

const QuizProvider = ({ children }) => {
  const [quizzes, setQuizzes] = useState([]);

  const fetchQuizzes = async (course_id) => {
    const alreadyFetch = quizzes.find(_quiz => _quiz.pivot.course_id === course_id)
    if (alreadyFetch) 
      return new Promise((resolve) => resolve({ data: quizzes }));

    return getQuizzes({ course_id })
      .then(res => {
        setQuizzes(res.data)
        return res
      })
      .catch(handleHttpErrors)
  }
  return (
    <QuizContext.Provider value={{ quizzes, fetchQuizzes }}>
      {children}
    </QuizContext.Provider>
  );
};

export default QuizProvider;

export const QUIZ_ATTEMPT_STATUS_PASSED = 'passed'