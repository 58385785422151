import React, { createContext, useState } from 'react';
import { getPayments } from '../services/payment';
import { handleHttpErrors } from '../utils/errorHandler';

export const PaymentContext = createContext();

const PaymentProvider = ({ children }) => {
  const [payments, setPayments] = useState([]);

  const fetchPayments = () => {
    getPayments()
      .then(res => {
        setPayments(res.data)
      })
      .catch(handleHttpErrors)
  }

  return (
    <PaymentContext.Provider value={{ payments, setPayments, fetchPayments }}>
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;

export const PAYMENT_STATUS_WAIT_USER_PAY = 'waiting_user_pay'
export const PAYMENT_STATUS_CANCELED = 'canceled'
export const PAYMENT_STATUS_PAID = 'paid'

export const paymentStatusText = (status) => {
  switch(status) {
    case PAYMENT_STATUS_WAIT_USER_PAY:
      return 'ยังไม่ชำระ'
    case PAYMENT_STATUS_CANCELED:
      return 'ยกเลิก'
    case PAYMENT_STATUS_PAID:
      return 'สำเร็จ'
    default:
      return ''
  }
}