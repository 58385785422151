import PageTitle from "../components/PageTitle";

export default function TermOfService() {
  return (
    <>
      <div class="page page-term">
        <PageTitle title={'ข้อกำหนดและเงื่อนไขการใช้บริการเว็บไซต์'} />

        ยินดีต้อนรับสู่ [career-development-udon.com] ("เรา", "เว็บไซต์", "บริการ") การใช้บริการเว็บไซต์นี้ถือว่าคุณ ("ผู้ใช้") ยอมรับข้อกำหนดและเงื่อนไขที่ระบุด้านล่าง โปรดอ่านข้อกำหนดและเงื่อนไขเหล่านี้อย่างละเอียดก่อนใช้งานเว็บไซต์
        <br />
        <div class="term-list">1. การยอมรับข้อกำหนด</div>
        เมื่อคุณเข้าถึงหรือใช้บริการของเรา คุณยอมรับว่าคุณได้อ่าน ทำความเข้าใจ และยอมรับที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขเหล่านี้ หากคุณไม่ยอมรับข้อกำหนดเหล่านี้ โปรดอย่าใช้บริการของเรา
        <br />
        <div class="term-list">2. การใช้งานเว็บไซต์</div>
        2.1 ผู้ใช้ต้องมีอายุตามกฎหมายหรือได้รับอนุญาตจากผู้ปกครองหรือผู้ดูแลจึงจะสามารถใช้บริการนี้ได้
        <br />2.2 คุณตกลงที่จะใช้บริการเพื่อวัตถุประสงค์ที่ถูกต้องตามกฎหมายและไม่ใช้บริการนี้ในการละเมิดกฎหมาย หรือก่อให้เกิดความเสียหายแก่บุคคลอื่น
        <br />2.3 เราขอสงวนสิทธิ์ในการแก้ไข เปลี่ยนแปลง หรือลบบริการหรือเนื้อหาใดๆ บนเว็บไซต์โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
        <br />
        <div class="term-list">3. สิทธิ์ในทรัพย์สินทางปัญญา</div>
        เนื้อหา ข้อมูล ภาพ เสียง วิดีโอ และสื่ออื่น ๆ ทั้งหมดบนเว็บไซต์นี้เป็นทรัพย์สินของ [career-development-udon.com] และได้รับการคุ้มครองตามกฎหมายลิขสิทธิ์ ห้ามทำการคัดลอก แก้ไข แจกจ่าย หรือใช้ประโยชน์ในทางพาณิชย์โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากเรา
        <br />
        <div class="term-list">4. การสมัครสมาชิก</div>
        4.1 การใช้งานบางส่วนของเว็บไซต์อาจต้องมีการสมัครสมาชิก คุณตกลงที่จะให้ข้อมูลที่ถูกต้องและเป็นปัจจุบันเมื่อทำการสมัครสมาชิก
        <br />4.2 เราขอสงวนสิทธิ์ในการยกเลิกหรือระงับบัญชีของคุณหากพบว่าคุณละเมิดข้อกำหนดและเงื่อนไขการใช้งานนี้
        <br />
        <div class="term-list">5. ความเป็นส่วนตัว</div>
        เราปฏิบัติตามนโยบายความเป็นส่วนตัวและจะรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณ รายละเอียดเพิ่มเติมโปรดดูที่ [นโยบายความเป็นส่วนตัว]
        <br />
        <div class="term-list">6. การปฏิเสธความรับผิดชอบ</div>
        เว็บไซต์และบริการทั้งหมดถูกให้ "ตามสภาพที่เป็น" และ "ตามที่มีอยู่" เราไม่รับประกันความถูกต้อง ความสมบูรณ์ หรือความน่าเชื่อถือของเนื้อหาหรือบริการที่ให้ไว้ และเราจะไม่รับผิดชอบต่อความเสียหายใดๆ ที่เกิดขึ้นจากการใช้บริการของเว็บไซต์นี้
        <br />
        <div class="term-list">7. การยุติการใช้งาน</div>
        เราขอสงวนสิทธิ์ในการยกเลิกหรือระงับการเข้าถึงเว็บไซต์ของคุณได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า หากเราพิจารณาว่าคุณได้ละเมิดข้อกำหนดและเงื่อนไขเหล่านี้
        <br />
        <div class="term-list">8. การแก้ไขข้อกำหนดและเงื่อนไข</div>
        เราขอสงวนสิทธิ์ในการแก้ไขข้อกำหนดและเงื่อนไขนี้ได้ตลอดเวลา การเปลี่ยนแปลงจะมีผลทันทีเมื่อเผยแพร่บนเว็บไซต์ คุณควรตรวจสอบข้อกำหนดและเงื่อนไขเป็นระยะเพื่อรับทราบถึงการเปลี่ยนแปลงใดๆ
        <br />
        <div class="term-list">9. กฎหมายที่ใช้บังคับ</div>
        ข้อกำหนดและเงื่อนไขนี้ถูกตีความและปฏิบัติตามกฎหมายของ [ประเทศ/รัฐ] และศาลในเขตอำนาจนี้จะเป็นผู้พิจารณาข้อพิพาทใดๆ ที่เกิดขึ้น
        <br />
        <div class="term-list">10. ติดต่อเรา</div>
        หากคุณมีข้อสงสัยหรือคำถามเกี่ยวกับข้อกำหนดและเงื่อนไขนี้ กรุณาติดต่อเราที่ LINE OFFICIAL
      </div>
    </>
  )
}